import React from 'react'
import { Box, Card, makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		flexWrap: 'wrap',
        backgroundColor: 'white',
        marginBottom: 15
	},
	logo: {
		margin: '20px 20px'
	}
}))

export default function LogoCard() {
	const classes = useStyles()
	const { tier1, tier2, tier3, tier4 } = useSelector(state => state.app.logosURL || { })

	if (!tier1 || tier1.length < 1) return null

	return (
		<Box >
			<Card className={classes.root}>
				{tier1.length > 0 &&
					tier1.map((l, i) => (
						<img key={'logo-' + i} className={classes.logo} alt={'logo-' + i} src={l} height='120px' />
					))}
			</Card>
			<Card className={classes.root}>
				{tier2.length > 0 &&
					tier2.map((l, i) => (
						<img key={'logo-' + i} className={classes.logo} alt={'logo-' + i} src={l} height='100px' />
					))}
			</Card>
			<Card className={classes.root}>
				{tier3.length > 0 &&
					tier3.map((l, i) => (
						<img key={'logo-' + i} className={classes.logo} alt={'logo-' + i} src={l} height='80px' />
					))}
			</Card>
			<Card className={classes.root}>
				{tier4.length > 0 &&
					tier4.map((l, i) => (
						<img key={'logo-' + i} className={classes.logo} alt={'logo-' + i} src={l} height='60px' />
					))}
			</Card>
		</Box>
	)
}
