import { SET_LOGIN, LOGOUT } from '../constants'

export const login = (state = {logged: false},a ) => {
    switch (a.type) {
        case SET_LOGIN:
            localStorage.setItem('token', a.payload.user.token)
            return {logged: true, ...a.payload.user, permissions: a.payload.permissions}    
        case LOGOUT:
            localStorage.removeItem('token')
            return {logged: false}
        default:
            return state
    }
}