import React, { useState, useEffect } from 'react'
import {
	Dialog,
	DialogTitle,
	TextField,
	FormControlLabel,
	DialogActions,
	Button,
	Switch,
	makeStyles,
	DialogContent,
	Divider,
	Box,
	RadioGroup,
	Radio,
	Typography,
    MenuItem
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { showSnack } from '../../actions/Feedback/showSnack'
import { encodeBase64 } from './encodeBase64'
import { apiPut, apiPost } from '../../api'
import { urlRooms } from '../../api/urls'
import { startLoading } from '../../actions/Feedback/startLoading'
import { endLoading } from '../../actions/Feedback/endLoading'

const useStyles = makeStyles(theme => ({
	labeledSwitch: {
		width: '100%',
		margin: '5px 0px',
		justifyContent: 'space-between'
	},
	diapoBox: {
		display: 'flex',
		flexDirection: 'column',
		border: '1px solid ' + theme.palette.divider,
		padding: 10,
		borderRadius: 4
	}
}))

const tamIcono = 15

export default function ConfigRoomDialog(props) {
	const classes = useStyles()
	const permissions = useSelector(state => state.login.permissions)
	const [ radioVal, setRadioVal ] = useState('none')
	const [ configure, setConfigure ] = useState({ room: {}, error: false })
	const [ diapositiva, setDiapositiva ] = useState('')
	const dispatch = useDispatch()

	const { open, onClose, edit, update } = props
	useEffect(() => {
        let room = props.room
        if (room) room.canShare = room.extras?.canShare
        setConfigure({ ...configure, room})	
        // eslint-disable-next-line
    }, [props.room])

    console.log(configure.room)

	const { room, error } = configure
	const urlSlide = room ? (room.extras ? room.extras.urlSlide : null) : null

	const onChangeRoom = room => setConfigure({ ...configure, room })

	const validateSize = (e, size) => {
		return e.size < size
	}

	const cleanConfigure = () => {
		setConfigure({ error: false, room: { settings: {} } })
		setDiapositiva('')
		setRadioVal('none')
	}

	const onSaveConfig = obj => {
		var extra = {}
		if (radioVal === 'file') extra.encodedSlide = obj.e ? obj.e.target.result : undefined
		else extra = obj
		if (!edit)
            if (configure.room.name){
                dispatch(startLoading())
				apiPost(urlRooms + '?create=' + configure.room.name, {
					settings: {...configure.room.settings, logoutURL: window.location.origin},
					...extra,
				}).then(r => {
					if (r.status === 'OK') {
						update()
						cleanConfigure()
						onClose()
						dispatch(showSnack('Sala creada con éxito', 'success'))
                    } else dispatch(showSnack('Error al crear sala', 'error'))
                    dispatch(endLoading())
				})
			}else {
				dispatch(showSnack('Error al crear sala', 'error'))
				setConfigure({ ...configure, error: true })
			}
		else if (configure.room.name) {
            dispatch(startLoading())
			apiPut(urlRooms, { ...configure.room, ...extra }).then(r => {
				if (r.status === 'OK') {
					update()
					cleanConfigure()
					onClose()
					dispatch(showSnack('Sala actualizada correctamente', 'success'))
                } else dispatch(showSnack('Error al actualizar la sala', 'error'))
                dispatch(endLoading())
			})
		} else {
			setConfigure({ ...configure, error: true })
		}
	}

	const save = () => {
		if (radioVal === 'file') encodeBase64(diapositiva, e => onSaveConfig({ e }))
		else if (radioVal === 'link') onSaveConfig({ urlSlide: diapositiva })
		else if (radioVal === 'delete') onSaveConfig({ deleteSlide: true })
		else onSaveConfig({})
	}

	const handleSwitchChange = e => {
		let value = e.target.checked
		if (e.target.name === 'guestPolicy') value = e.target.checked ? 'ASK_MODERATOR' : 'ALWAYS_ACCEPT'
		if (e.target.name === 'record')
			onChangeRoom({ ...room, settings: { ...room.settings, record: value, allowStartStopRecording: value } })
		else onChangeRoom({ ...room, settings: { ...room.settings, [e.target.name]: value } })
    }
    
    const handleMaxPart = e => {
        onChangeRoom({...room, settings: { ...room.settings, maxParticipants: e.target.value}})
    }

	if (!room || !open) return null

	return (
		<Dialog open={open}>
			<DialogTitle>{edit ? 'Configuración de' : 'Crear'} sala</DialogTitle>
			<Divider />
			<DialogContent>
				<TextField
					variant='filled'
					label='Nombre'
					value={room.name || ''}
					name='name'
					onChange={e => onChangeRoom({ ...room, name: e.target.value })}
					error={error && !room.name}
					fullWidth
					required
				/>
				<FormControlLabel
					className={classes.labeledSwitch}
					checked={room.settings.muteOnStart || false}
					onChange={handleSwitchChange}
					name='muteOnStart'
					value='start'
					control={<Switch color='primary' />}
					label='Deshabilitar micrófono de usuario al entrar'
					labelPlacement='start'
				/>
				<FormControlLabel
					className={classes.labeledSwitch}
					checked={room.settings.guestPolicy === 'ASK_MODERATOR' || false}
					onChange={handleSwitchChange}
					name='guestPolicy'
					value='start'
					control={<Switch color='primary' />}
					label='Requerir aprobación del moderador antes de ingresar a la sesión'
					labelPlacement='start'
				/>
				{permissions.indexOf('PUEDE GRABAR') !== -1 ? (
					<FormControlLabel
						className={classes.labeledSwitch}
						checked={room.settings.record || false}
						onChange={handleSwitchChange}
						name='record'
						value='start'
						control={<Switch color='primary' />}
						label='Permitir grabar la sesión'
						labelPlacement='start'
					/>
				) : null}
				<FormControlLabel
					className={classes.labeledSwitch}
					checked={room.canShare || false}
					onChange={e => onChangeRoom({...room, canShare: e.target.checked})}
					name='canShare'
					value='start'
					control={<Switch color='primary' />}
					label='Permitir a los usuarios compartir el link de la sala'
					labelPlacement='start'
				/>
                {permissions.indexOf('MAX PARTICIPANTES') !== -1 ?<TextField
                    value={room.settings.maxParticipants || false}
                    name='maxParticipants'
                    onChange={handleMaxPart}
                    select
                    fullWidth
                    margin='normal'
                    variant='filled'
                    label='Cantidad máxima de participantes'
                >
                    {[1, 5, 10, 15, 20, 30, 40, 50, 100].map(e =>
                       <MenuItem value={e} key={e}>
                            {e}
                        </MenuItem> 
                    )}
                </TextField> : null}
				<Box className={classes.diapoBox}>
					<Typography paragraph>{urlSlide ? 'Cambiar d' : 'D'}iapositiva de presentacion</Typography>
					<RadioGroup
						name='radioVal'
						value={radioVal}
						onChange={({ target }) => {
							setRadioVal(target.value)
							setDiapositiva('')
						}}
					>
						<FormControlLabel
							value='none'
							control={<Radio />}
							label={urlSlide ? 'Mantener diapositiva actual' : 'No tengo diapositiva'}
						/>
						{urlSlide ? (
							<FormControlLabel value='delete' control={<Radio />} label={'Borrar diapositiva'} />
						) : null}
						<FormControlLabel value='link' control={<Radio />} label='Tengo el link de la diapositiva' />
						<FormControlLabel
							value='file'
							control={<Radio />}
							label='Tengo la diapositiva en mi computadora'
						/>
					</RadioGroup>
					{radioVal === 'link' ? (
						<TextField
							margin='dense'
							name='diapositiva'
							value={diapositiva}
							onChange={({ target }) => setDiapositiva(target.value)}
							variant='filled'
							label='Ingrese el link a la diapositiva'
						/>
					) : radioVal === 'file' ? (
						<Box>
							<TextField
								className={classes.input}
								style={{ width: '70%' }}
								name='diapositiva'
								value={diapositiva ? diapositiva.name : ''}
								variant='filled'
								label='Archivo'
								inputProps={{ readOnly: true }}
								//disabled={edit && !editarIcono}
							/>
							<Button
								style={{ width: '25%', marginLeft: '2%', alignSelf: 'center', marginBottom: 10 }}
								variant='contained'
								color='primary'
								component='label'
								//disabled={edit && !editarIcono}
							>
								Subir archivo
								<input
									accept='application/pdf'
									style={{ display: 'none' }}
									id='path'
									type='file'
									onChange={e => {
										if (e.target.files[0]) {
											if (validateSize(e.target.files[0], tamIcono * 1024 * 1024))
												setDiapositiva(e.target.files[0])
											else
												dispatch(
													showSnack(
														'El tamaño del documento no pude superar ' + tamIcono + 'mb',
														'info'
													)
												)
										}
									}}
								/>
							</Button>
						</Box>
					) : null}
				</Box>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button onClick={onClose}>Cancelar</Button>
				<Button variant='contained' color='primary' onClick={save}>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
