import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

export const footerHeight = 64

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: footerHeight-1,
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid '+(theme.palette.type === 'dark'? '#282828' : 'rgba(0,40,100,0.12)'),
    }
}))

export default function Footer(){
    const classes = useStyles()

    return <footer className={classes.root}>
        <Typography variant='subtitle2' color='textPrimary'>Copyright (C) GoAhead</Typography>
    </footer>
}