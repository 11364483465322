import React, { useState, useEffect } from 'react'
import {
	makeStyles,
	Box,
	Dialog,
	DialogTitle,
	Divider,
	DialogContent,
	Typography,
	DialogActions,
	Button
} from '@material-ui/core'
import { MeetingRoomRounded, SettingsRounded, GroupRounded, DeleteRounded } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import GenericTable from '../../resources/GenericTable'
import { apiGetWithParams, apiDelete } from '../../../api'
import { urlRooms } from '../../../api/urls'
import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'
import AddUsersDialog from '../../resources/AddUsersDialog'
import ConfigRoomDialog from '../../resources/ConfigRoomDialog'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		}
	}
}))

const cols = [
	{ id: 'name', search: true, numeric: false, disablePadding: false, label: 'Nombre' },
	{ id: 'user', search: true, numeric: false, disablePadding: false, label: 'Dueño' },
	{ id: 'uid', search: true, numeric: false, disablePadding: false, label: 'ID' },
	{ id: 'running', search: false, boolean: true, disablePadding: false, label: 'Iniciada' }
]

export default function Rooms(props) {
	const classes = useStyles()
	const permissions = useSelector(state => state.login.permissions)
	const [ data, setData ] = useState([])
	const [ doFetch, setDoFetch ] = useState(0)
	const [ configure, setConfigure ] = useState({ room: { settings: {} }, error: false, open: false })
	const dispatch = useDispatch()
	const [ addUsers, setAddUsers ] = useState({ open: false, room: {} })
	const [ delRooms, setDelRooms ] = useState({ open: false, ids: [] })

	const { search } = props

	useEffect(
		() => {
			dispatch(startLoading())
			apiGetWithParams(urlRooms, { allRooms: true }).then(r => {
				if (r.status === 'OK') {
					setData(r.data)
				} else dispatch(showSnack('Error al obtener las salas', 'error'))
				dispatch(endLoading())
			})
		},
		[ setData, dispatch, doFetch ]
	)

	const cleanConfigure = () => {
		setConfigure({ room: { settings: {} }, error: false, open: false })
	}

	const onDelRoom = () => {
		dispatch(startLoading())
		apiDelete(urlRooms, { ids: delRooms.ids }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				setDelRooms({ open: false, ids: [] })
				dispatch(showSnack('Sala eliminada con éxito', 'success'))
			} else dispatch(showSnack('Error al eliminar sala', 'error'))
			dispatch(endLoading())
		})
	}

	const join = room => {
		dispatch(startLoading())
		apiGetWithParams(urlRooms, { join: room.uid }).then(r => {
			if (r.status === 'OK') window.location.replace(r.data.url)
			else if (r.status === 'NOT_RUNNING')
				apiGetWithParams(urlRooms, { start: room.uid }).then(r => {
					if (r.status === 'OK') window.location.replace(r.data.url)
					else dispatch(showSnack('No es posible iniciar esta sala, vuelva a intentar mas tarde', 'error'))
				})
			else dispatch(showSnack('No es posible unirse a esta sala, vuelva a intentar mas tarde', 'error'))
			dispatch(endLoading())
		})
	}

	let rowOptions = [ { label: 'Entrar', action: room => join(room), icon: MeetingRoomRounded } ]

	let menuOptions = null

	if (permissions.indexOf('CONFIG SALA') !== -1)
		rowOptions.push({
			label: 'Configuración',
			action: room => setConfigure({ error: false, room, open: true }),
			icon: SettingsRounded
		})
	if (permissions.indexOf('MANAGE ACCESS') !== -1)
		rowOptions.push({
			label: 'Administrar acceso',
			action: room => setAddUsers({ open: true, room }),
			icon: GroupRounded
		})
	if (permissions.indexOf('BORRAR SALA') !== -1) {
		rowOptions.push({
			label: 'Eliminar',
			action: room => setDelRooms({ open: true, ids: [ room.id ] }),
			icon: DeleteRounded
		})
		menuOptions = [ { label: 'Eliminar', action: ids => setDelRooms({ open: true, ids }), icon: DeleteRounded } ]
	}

	return (
		<Box className={classes.root}>
			<ConfigRoomDialog
				open={configure.open}
				edit={true}
				room={configure.room}
				onClose={cleanConfigure}
				update={() => setDoFetch(doFetch + 1)}
			/>
			<Dialog open={delRooms.open}>
				<DialogTitle>Eliminar sala{delRooms.ids.length > 1 ? 's' : ''}</DialogTitle>
				<Divider />
				<DialogContent>
					<Typography>
						¿Está seguro que desea eliminar {delRooms.ids.length} sala{delRooms.ids.length > 1 ? 's' : ''}?
					</Typography>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button color='secondary' onClick={() => setDelRooms({ open: false, ids: [] })}>
						Cancelar
					</Button>
					<Button variant='contained' color='primary' onClick={onDelRoom}>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
			<AddUsersDialog
				open={addUsers.open}
				room={addUsers.room}
				onClose={() => setAddUsers({ open: false, room: {} })}
			/>
			<GenericTable search={search} cols={cols} data={data} menuOptions={menuOptions} rowOptions={rowOptions} />
		</Box>
	)
}
