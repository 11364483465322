import React from 'react'
import { Box, makeStyles, Typography } from '@material-ui/core'
import { ReactSVG } from 'react-svg'

import { topBarHeight } from '../TopBar'
import Footer, { footerHeight } from '../Footer'
import WL from '../../imgs/image-welcome.svg'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
	root: {
        height: 'calc(100vh - ' + topBarHeight + 'px - ' + footerHeight + 'px - 120px)',
        padding: '60px 0px',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-around'
    },
    logo: {
        height: '70%',
        width: '80%'
    }
}))

export default function Welcome() {
	const classes = useStyles()

    const title = useSelector(state => state.app.siteTitle || 'Go Ahead')
    const subtitle = useSelector(state => state.app.subtitle || 'clases en vivo')

	return (
		<React.Fragment>
			<Box className={classes.root}>
				<Typography align='center' variant='h3' color='textPrimary'>
					{title.toUpperCase()} - SALA DIGITAL.
				</Typography>
				<Typography variant='h4' color='textPrimary'>
					{subtitle}
				</Typography>
				<ReactSVG
                    src={WL}
                    wrapper={'span'}
					beforeInjection={svg => {
						svg.setAttribute('style', 'height: 100%; width: 100%')
                    }}
                    className={classes.logo}
				/>
			</Box>
			<Footer />
		</React.Fragment>
	)
}
