import React, { useState } from 'react'
import { makeStyles, TextField, List, ListItem, ListItemText, IconButton, CircularProgress } from '@material-ui/core'
import { apiGetWithParams } from '../../api'
import { useDispatch } from 'react-redux'
import { showSnack } from '../../actions/Feedback/showSnack'
import { CloseRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
	textfield: {
		Margin: '10px 0px'
	},
	list: {
		boxShadow: theme.shadows[3],
		borderRadius: 4,
		position: 'absolute',
		width: 'calc(100% - 48px)',
		zIndex: 2000,
		backgroundColor: theme.palette.background.paper,
		marginTop: 1,
		maxHeight: 400
	}
}))

export default function Autocomplete(props) {
	const classes = useStyles()
	const [ state, setState ] = useState({ open: false, search: '', selected: {} })
	const [ users, setUsers ] = useState([])
	const [ loading, setLoading ] = useState(false)
	const [ listOpen, setListOpen ] = useState(false)
	const [ timer, setTimer ] = useState(-1)
	const dispatch = useDispatch()

	const { label, url, onClick } = props

	const onChange = e => {
		const search = e.target.value
		setState({ ...state, search })
		if (search.length > 2) {
			let idTimer = -1
			clearTimeout(timer)
			idTimer = setTimeout(() => {
				setLoading(true)
				apiGetWithParams(url, { search }).then(r => {
					if (r.status === 'OK') setUsers(r.data)
					else dispatch(showSnack('Error al obtener usuarios', 'error'))
					setLoading(false)
				})
			}, 400)
			setTimer(idTimer)
		}
	}

	const itemClick = user => {
		setListOpen(false)
		onClick(user)
		setState({ ...state, search: '' })
	}

	return (
		<React.Fragment>
			<TextField
				className={classes.textfield}
				onFocus={() => setListOpen(true)}
				label={label}
				variant='filled'
				name='search'
				value={state.search}
				onChange={onChange}
				fullWidth
				autoFocus
				InputProps={{
					endAdornment: loading ? (
						<CircularProgress style={{width: 25, height: 'unset'}} color='primary' />
					) : listOpen ? (
						<IconButton size='small' color='secondary' onClick={() => setListOpen(false)}>
							<CloseRounded />
						</IconButton>
					) : null
				}}
			/>
			{users.length > 0 && listOpen ? (
				<List className={classes.list} onBlur={() => setListOpen(false)}>
					{users.map(u => (
						<ListItem button onClick={() => itemClick(u)} key={'li-' + u.id}>
							<ListItemText key={'lit-' + u.id} primary={u.fullName} />
						</ListItem>
					))}
				</List>
			) : null}
		</React.Fragment>
	)
}
