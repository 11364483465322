import React, { useState } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { showSnack } from '../../actions/Feedback/showSnack'

export default function ChangePassDialog(props) {
	const [ state, setState ] = useState({ oldPass: '', newPass: '', newPass2: '', error: false, distintas: false })
	const dispatch = useDispatch()

	const { open, onClose, onSubmit } = props

	const onSave = () => {
		if (state.newPass && state.newPass2 && state.oldPass)
			if (state.newPass !== state.newPass2) {
				dispatch(showSnack('Las contraseñas no coinciden', 'info'))
				setState({ ...state, distintas: true, error: false })
			} else {
                onSubmit(state)
                onClose()
            }    
		else {
			dispatch(showSnack('Uno o mas campos están vacíos', 'info'))
			setState({ ...state, error: true, distintas: false })
		}
	}

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
			<DialogTitle>Cambiar contraseña</DialogTitle>
			<Divider />
			<DialogContent style={{display: 'flex', flexDirection: 'column'}}>
				<TextField
                    margin='dense'
                    fullWidth
                    type='password'
                    label='Contraseña anterior'
					name='oldPass'
					value={state.oldPass}
					onChange={e => setState({ ...state, [e.target.name]: e.target.value })}
                    variant='filled'
                    error={!state.oldPass && state.error}
				/>
				<TextField
                    margin='dense'
                    fullWidth
                    type='password'
                    label='Nueva contraseña'
					name='newPass'
					value={state.newPass}
					onChange={e => setState({ ...state, [e.target.name]: e.target.value })}
                    variant='filled'
                    error={(!state.newPass && state.error) || state.distintas}
				/>
				<TextField
                    margin='dense'
                    fullWidth
                    type='password'
                    label='Confirmar nueva contraseña'
					name='newPass2'
					value={state.newPass2}
					onChange={e => setState({ ...state, [e.target.name]: e.target.value })}
                    variant='filled'
                    error={(!state.newPass2 && state.error) || state.distintas}
				/>
			</DialogContent>
			<Divider />
			<DialogActions>
				<Button onClick={onClose}>
					Cancelar
				</Button>
				<Button variant='contained' color='primary' onClick={onSave}>
					Guardar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
