import { SHOW_SNACK, CLOSE_SNACK, START_LOADING, END_LOADING } from '../constants'

export const feedback = (state = { open: false, text: '', type: '', loading: 0 }, a) => {
	switch (a.type) {
		case SHOW_SNACK:
			return { ...state, open: true, ...a.payload }
		case CLOSE_SNACK:
			return { ...state, open: false }
		case START_LOADING: 
			return { ...state, loading: state.loading+1}
		case END_LOADING:
			return { ...state, loading: state.loading-1}
		default:
			return state
	}
}