import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	makeStyles,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	ListItem,
	ListItemText,
	List,
	Divider,
	IconButton,
	ListItemSecondaryAction,
	Typography,
	Box
} from '@material-ui/core'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'

import { apiGet, apiDelete, apiPost } from '../../api'
import { urlMembersGetRoomUsers, urlMembersDeleteUser, urlUsers, urlMembersToRoom } from '../../api/urls'
import { showSnack } from '../../actions/Feedback/showSnack'
import Autocomplete from './Autocomplete'
import { startLoading } from '../../actions/Feedback/startLoading'
import { endLoading } from '../../actions/Feedback/endLoading'
import { green, red } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
	list: {
		flex: 1
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.80)',
		borderRadius: 4
	},
	listcontainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
		width: '100%',
		borderRadius: 4
	},
	green: {
		backgroundColor: theme.palette.type === 'dark' ? green[300] : green[500],
		color: theme.palette.type === 'dark' ? 'black' : 'white',
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? green[400] : green[700],
			color: theme.palette.type === 'dark' ? 'black' : 'white'
		}
	},
	red: {
		backgroundColor: theme.palette.type === 'dark' ? red[300] : red[500],
		color: theme.palette.type === 'dark' ? 'black' : 'white',
		'&:hover': {
			backgroundColor: theme.palette.type === 'dark' ? red[400] : red[700],
			color: theme.palette.type === 'dark' ? 'black' : 'white'
		}
	}
}))

export default function AddUsersDialog(props) {
	const classes = useStyles()
	const [ users, setUsers ] = useState([])
	const [ doFetch, setDoFetch ] = useState(0)
	const [ del, setDel ] = useState({ open: false, user: {} })

	const dispatch = useDispatch()

	const { open, room, onClose } = props

	useEffect(
		() => {
			dispatch(startLoading())
			apiGet(urlMembersGetRoomUsers(room.uid)).then(r => {
				if (r.status === 'OK') setUsers(r.data)
				else dispatch(showSnack('Error al obtener usuarios', 'error'))
				dispatch(endLoading())
			})
		},
		[ dispatch, room.uid, setUsers, doFetch ]
	)

	const delUser = user => {
		dispatch(startLoading())
		apiDelete(urlMembersDeleteUser(room.uid), { users: [ user.user_id ] }).then(r => {
			if (r.status === 'OK') {
				setDel({ open: false, user: {} })
				dispatch(showSnack('Usuario removido con éxito', 'success'))
				setDoFetch(doFetch + 1)
			} else dispatch(showSnack('Error al remover usuario', 'error'))
			dispatch(endLoading())
		})
	}

	const addUser = user => {
		dispatch(startLoading())
		apiPost(urlMembersToRoom(room.uid), { users: [ user.id ] }).then(r => {
			if (r.status === 'OK') {
				dispatch(showSnack(`Usuario agregado con éxito`, 'success'))
				setDoFetch(doFetch - 1)
			} else dispatch(showSnack('Error al agregar usuario', 'error'))
			dispatch(endLoading())
		})
    }
    
    const setAdmin = (value, user) => {
		dispatch(startLoading())
		apiPost(urlMembersToRoom(room.uid), { users: [ user.user_id ], options: {canStart: value} }).then(r => {
			if (r.status === 'OK') {
				dispatch(showSnack(`Cambio realizado con éxito`, 'success'))
				setDoFetch(doFetch - 1)
			} else dispatch(showSnack('Error al realizar los cambios', 'error'))
			dispatch(endLoading())
		})
    }

	return (
		<React.Fragment>
			<Dialog open={del.open} fullWidth maxWidth='xs'>
				<DialogTitle>Remover acceso</DialogTitle>
				<Divider />
				<DialogContent>
					<Typography>
						¿Está seguro que desea remover el acceso de {del.user.user_name} a esta sala?
					</Typography>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => setDel({ open: false, user: {} })}>Cancelar</Button>
					<Button color='primary' variant='contained' onClick={() => delUser(del.user)}>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={open} fullWidth maxWidth='sm'>
				<DialogTitle>{room.name}</DialogTitle>
				<Divider />
				<DialogContent>
					<Autocomplete url={urlUsers} label={'Buscar'} onClick={e => addUser(e)} />
					<Typography variant='subtitle2'>Usuarios con acceso:</Typography>
					<Box className={classes.listcontainer}>
						<List className={classes.list}>
							<Divider />
							{users.map((u, i) => (
								<React.Fragment key={'rf-' + u.user_id}>
									<ListItem className={classes.listItem} key={'li-' + u.user_id}>
										<ListItemText key={'lit-' + u.user_id} primary={u.user_name} />
										<ListItemSecondaryAction>
											<Button
												className={u.options && u.options.canStart ? classes.green : classes.red}
												variant='contained'
												aria-label='admin'
												onClick={() => setAdmin(!u.options.canStart, u )}
											>
												Admin
											</Button>
											<IconButton
												color='secondary'
												edge='end'
												aria-label='delete'
												onClick={() => setDel({ open: true, user: u })}
											>
												<DeleteRoundedIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
									<Divider key={'f-' + u.user_id} />
								</React.Fragment>
							))}
						</List>
					</Box>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button variant='contained' color='primary' onClick={onClose}>
						Salir
					</Button>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	)
}
