import React, { useEffect, useState } from 'react'
import { Box, Button, makeStyles, TextField, Typography } from '@material-ui/core'
import { ErrorRounded } from '@material-ui/icons'
import { apiGetWithParams } from '../../api'
import { invitationLink, urlGetMeeting, urlJoin, urlRooms } from '../../api/urls'
import { useDispatch, useSelector } from 'react-redux'
import { showSnack } from '../../actions/Feedback/showSnack'
import { useParams } from 'react-router-dom'
import LogoCard from '../resources/LogoCard'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '80%',
		padding: '10%',
		paddingTop: 30
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		margin: 15,
		marginLeft: 0
	},
	rowCenterAlign: {
		display: 'flex',
		alignItems: 'center',
        marginTop: '15px',
        marginBottom: 15
	},
	iniciarButton: {
		height: '80%',
		width: '10%',
		fontSize: 25,
		marginRight: '15px'
	},
	textfield: {
		width: '50%'
	}
}))

export default function Join() {
	const classes = useStyles()
    const login = useSelector(state => state.login)
    const reqLog = useSelector(state => state.app.authRoom)
    const [ room, setRoom ] = useState({})
    const [ doFetch, setDoFetch ] = useState(0)
	const [ name, setName ] = useState(login.logged ? login.user.fullName : '')
	const dispatch = useDispatch()

	const { uid } = useParams()

	useEffect(
		() => {
            let url = urlGetMeeting
            let params = { meetingID: uid }
            if(login.logged){
                url = urlRooms
                params.getMeetings = true
            }
            
            apiGetWithParams(url, params).then(r => {
				if (r.status === 'OK') setRoom(r.data)
				else dispatch(showSnack('Error al obtener informacion de la sala', 'error'))
			})
		},
		[dispatch, login.logged, uid, doFetch]
	)

	const join = () => {
		let url = urlJoin
        let params = { uid, name }
        
        if (login.logged){
            url = urlRooms
            params = { join: uid, name: login.user.fullName }
        }

		if (room.options?.canStart && !room.running) {
			url = urlRooms
			params = { start: room.uid, name, logoutURL: invitationLink+room.uid }
		}
		apiGetWithParams(url, params).then(r => {
			if (r.status === 'OK') 
                window.location.replace(r.data.url)
            else if (r.status === 'NOT_RUNNING'){
                dispatch(showSnack('La sala no está iniciada'))
                setDoFetch(doFetch+1)
			} else dispatch(showSnack('Algo salió mal, vuelva a intentar mas tarde', 'error'))
		})
    }
    
	return (
		<Box className={classes.root}>
			<Box className={classes.title}>
				<Typography variant='h2' color='textPrimary'>
					{room.name || 'Cargando...'}
				</Typography>
			</Box>
			{room.name && (!reqLog || login.logged) ? (
				<TextField
					className={classes.textfield}
					name='name'
					value={name}
					onChange={e => setName(e.target.value)}
					label='Nombre'
					variant='filled'
                    helperText='Los demás participantes lo verán con éste nombre'
                    disabled={login.logged}
				/>
			) : null}
			{!reqLog || login.logged? <Box className={classes.rowCenterAlign}>
				<Button
					className={classes.iniciarButton}
					variant='contained'
					color='primary'
					onClick={() => join()}
					disabled={!name || (!room.running && !room.options?.canStart)}
				>
					{!room.running && room.options?.canStart? 'Iniciar' : 'Unirse'}
				</Button>
				{!room.running ? (
					<Box display='flex'>
						<ErrorRounded style={{ marginBottom: 3, marginRight: 5 }} color='error' />
						<Typography variant='subtitle1' color='textPrimary'>
							Sala no iniciada
						</Typography>
					</Box>
				) : null}
			</Box> : <Typography variant='h5' color='textPrimary'>Para unirte a esta sala es necesario iniciar sesion</Typography>}
            <LogoCard />
		</Box>
	)
}
