import { combineReducers } from 'redux'
import { login } from './login'
import { theme } from './theme'
import { feedback } from './feedback'
import { app } from './app'

export default combineReducers({
    login,
    theme,
    feedback,
    app,
})