import React, { useState } from 'react'
import { Box, makeStyles, Paper, Typography, TextField, Divider, Button } from '@material-ui/core'
import { useDispatch } from 'react-redux'

import { topBarHeight } from '../TopBar'
import { setLogin } from '../../actions/login/setLogin'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 'calc(100vh - ' + topBarHeight + 'px)',
		flexDirection: 'column',
		width: '100%',
	},
	paper: {
		minHeight: '50%',
		minWidth: '30%',
	},
	inputBox: {
		padding: 20,
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	textfield: {
		margin: '10px 0px',
	},
	loginButton: {
		marginTop: 10,
	},
	cardTitle: {
		width: '100%',
		margin: '20px 0px',
	},
}))

export default function Register() {
	const classes = useStyles()
	const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [nombre, setNombre] = useState('')
	const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')

	const login = () => {
		dispatch(setLogin({ darkMode: localStorage.getItem('prefersDarkMode') }))
	}

	return (
		<Box className={classes.root}>
			<Paper className={classes.paper}>
				<Typography className={classes.cardTitle} align='center' variant='h6'>
					Crea una cuenta
				</Typography>
				<Divider />
				<Box className={classes.inputBox}>
					<TextField
						placeholder='Nombre completo'
						label='Nombre completo'
						className={classes.textfield}
						name='nombre'
						variant='filled'
						value={nombre}
						onChange={(e) => setNombre(e.target.value)}
						color='primary'
						required
						fullWidth
					/>
					<TextField
						placeholder='Correo'
						label='Correo'
						className={classes.textfield}
						name='username'
						variant='filled'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						color='primary'
						required
						fullWidth
					/>
					<TextField
						placeholder='Contraseña'
						label='Contraseña'
						className={classes.textfield}
						name='password'
						variant='filled'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						color='primary'
						required
						type='password'
						fullWidth
					/>
					<TextField
						placeholder='Confirmar contraseña'
						label='Confirmar contraseña'
						className={classes.textfield}
						name='password2'
						variant='filled'
						value={password2}
						onChange={(e) => setPassword2(e.target.value)}
						color='primary'
						required
						type='password'
						fullWidth
					/>
					<Divider style={{ width: '100%' }} />
					<Button className={classes.loginButton} variant='contained' color='primary' onClick={login}>
						Registrarse
					</Button>
				</Box>
			</Paper>
		</Box>
	)
}
