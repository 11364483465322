import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Divider } from '@material-ui/core'
/* import { format } from 'date-fns'
 */
export default function ProfileDialog(props) {

    const { open, onClose, data } = props
    
    if (!data.user) return null

    //const [y, m, d] = data.birthday.split('-')
    //const fechaNac = format(new Date(y, m-1, d), 'dd/MM/yyyy')

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
			<DialogTitle>Perfil de usuario</DialogTitle>
            <Divider/>
			<DialogContent>
                <Typography paragraph>Nombre de usuario: {data.user.username}</Typography>
                <Typography paragraph>Nombre completo: {data.user.fullName}</Typography>
{/*                 <Typography paragraph>Mail: {data.user.email}</Typography>
                <Typography paragraph>Fecha de nacimiento: {fechaNac}</Typography> */}
                <Typography>Rol: {data.role}</Typography>
            </DialogContent>
            <Divider/>
			<DialogActions>
				<Button variant='contained' color='primary' onClick={onClose}>
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	)
}
