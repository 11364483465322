import React, { useState, useEffect } from 'react'
import {
	makeStyles,
	Box,
	ButtonGroup,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	CircularProgress,
	List,
	ListItem,
	ListItemText,
	DialogActions,
	Divider,
	DialogContentText,
	TextField,
	MenuItem
} from '@material-ui/core'
/* import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import esLocale from 'date-fns/locale/es' */

import GenericTable from '../../resources/GenericTable'
import { apiGetWithParams, apiGet, apiPost, apiPut, apiDelete } from '../../../api'
import { urlUsers, urlRoles, urlMembersToRoom, urlRooms, urlLogin } from '../../../api/urls'
import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'
import { setLogin } from '../../../actions/login/setLogin'
import { useDispatch, useSelector } from 'react-redux'
/* import { format } from 'date-fns'
 */
const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	selected: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		}
	},
	selectedItem: {
		borderRadius: 4,
		color: theme.palette.primary.main
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.80)',
		borderRadius: 4
	},
	textField: {
		margin: '5px 0px'
	},
	nuevoButton: {
		backgroundColor: theme.palette.success.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: theme.palette.success.dark,
			color: theme.palette.getContrastText(theme.palette.primary.main)
		},
		marginRight: 15
	}
}))

const cols = [
	{ id: 'fullName', search: true, numeric: false, disablePadding: false, label: 'Nombre completo' },
	{ id: 'username', search: true, numeric: false, disablePadding: false, label: 'Nombre de usuario' },
	{ id: 'role', search: false, numeric: false, disablePadding: false, label: 'Rol' }
]

export default function Users(props) {
	const classes = useStyles()
	const permissions = useSelector(state => state.login.permissions)
	const [ data, setData ] = useState([])
	const [ tipo, setTipo ] = useState('activos')
	const [ rol, setRol ] = useState({ id: -1, name: 'todos' })
	const [ getUsers, setGetUsers ] = useState(0)
	const [ roles, setRoles ] = useState([])
	const [ selectRoom, setSelectRoom ] = useState({
		open: false,
		selectedIds: [],
		rooms: [],
		roomId: false,
		loading: false
	})
	const [ rowInfo, setRowInfo ] = useState({
		nuevo: false,
		edit: false,
		del: false,
		activar: false,
		row: {},
		error: false
	})
	const dispatch = useDispatch()

	const { search } = props

	useEffect(
		() => {
			dispatch(startLoading())
			apiGet(urlRoles).then(r => {
				if (r.status === 'OK') setRoles(r.data)
				else dispatch(showSnack('Error al obtener los roles', 'error'))
				dispatch(endLoading())
			})
		},
		[ dispatch, setRoles ]
	)

	useEffect(
		() => {
			dispatch(startLoading())
			apiGetWithParams(urlUsers, { tipo, rol: rol.id }).then(r => {
				if (r.status === 'OK') {
					setData(r.data)
				} else dispatch(showSnack('Error al obtener usuarios', 'error'))
				dispatch(endLoading())
			})
		},
		[ setData, dispatch, tipo, rol, getUsers ]
	)

	const addToRoom = () => {
		dispatch(startLoading())
		apiPost(urlMembersToRoom(selectRoom.roomId), { users: selectRoom.selectedIds }).then(r => {
			if (r.status === 'OK') {
				dispatch(
					showSnack(
						`Usuario${selectRoom.selectedIds.length > 1 ? 's' : ''} agregado${selectRoom.selectedIds
							.length > 1
							? 's'
							: ''} con éxito`,
						'success'
					)
				)
				setSelectRoom({ open: false, rooms: [], selectedIds: [], roomId: -1 })
			} else dispatch(showSnack('Ocurrió un error', 'error'))
			dispatch(endLoading())
		})
	}

	const openSelectRoom = selectedIds => {
		setSelectRoom({ ...selectRoom, open: true, selectedIds, loading: true })
		dispatch(startLoading())
		apiGetWithParams(urlRooms, { allRooms: true, running: false }).then(r => {
			if (r.status === 'OK')
				setSelectRoom({ ...selectRoom, open: true, selectedIds, rooms: r.data, loading: false })
			else {
				dispatch(showSnack('Error al obtener las salas', 'error'))
				setSelectRoom({ ...selectRoom, loading: false })
			}
			dispatch(endLoading())
		})
	}

	const cleanRowInfo = () => {
		setRowInfo({ activar: false, edit: false, del: false, row: {}, error: false })
	}
	console.log(rowInfo)
	const onSaveUser = () => {
		const { id, username, password, password2, first_name, last_name, dni, roleType } = rowInfo.row
		const api = rowInfo.nuevo ? apiPost : apiPut
		if (rowInfo.nuevo || password === password2)
			if (
				(id || rowInfo.nuevo) &&
				username &&
				first_name &&
				last_name &&
				dni &&
				roleType &&
				(password || rowInfo.edit)
			) {
				dispatch(startLoading())
				api(urlUsers, {
					id,
					username,
					first_name,
					last_name,
					dni,
					roleType,
                    password: password || '',
                    birthday: '01-01-1995'
				}).then(r => {
					if (r.status === 'OK') {
						setGetUsers(getUsers + 1)
						dispatch(showSnack('Usuario guardado con éxito', 'success'))
						cleanRowInfo()
						const token = localStorage.getItem('token')
						dispatch(startLoading())
						apiPost(urlLogin, { token }).then(r => {
							if (r.status === 'OK') {
								dispatch(setLogin(r.data))
							}
							dispatch(endLoading())
						})
						//tengo que buscar mi user y actualizar mi perfil en redux por las dudas
					} else dispatch(showSnack('Error al guardar usuario', 'error'))
					dispatch(endLoading())
				})
			} else {
				setRowInfo({ ...rowInfo, error: true })
				dispatch(showSnack('Uno o mas campos están sin completar', 'info'))
			}
		else dispatch(showSnack('Las contraseñas no coinciden', 'info'))
	}

	const onDeleteUser = () => {
		dispatch(startLoading())
		apiDelete(urlUsers, { id: rowInfo.row.id }).then(r => {
			if (r.status === 'OK') {
				setGetUsers(getUsers - 1)
				dispatch(showSnack('Usuario eliminado con éxito', 'success'))
				cleanRowInfo()
			} else dispatch(showSnack('Error al eliminar usuario', 'error'))
			dispatch(endLoading())
		})
	}

	const setEditar = row => {
		const [ y, m, d ] = row.birthday ? row.birthday.split('-') : [ 0, 0, 0 ]
		const birthday = row.birthday ? new Date(y, m - 1, d) : new Date()
		setRowInfo({ edit: true, row: { ...row, birthday } })
	}

	const onActiveUser = () => {
		dispatch(startLoading())
		apiPut(urlUsers, { ids: rowInfo.row, activate: true }).then(r => {
			if (r.status === 'OK') {
				setGetUsers(getUsers - 1)
				dispatch(showSnack('Acción realizada con éxito', 'success'))
				cleanRowInfo()
			} else dispatch(showSnack('Ocurrió un error', 'error'))
			dispatch(endLoading())
		})
	}

	let menuOptions = []

	let rowOptions = []

	if (permissions.indexOf('MANAGE ACCESS') !== -1) {
		rowOptions.push({ label: 'Agregar a sala', action: row => openSelectRoom([ row.id ]) })
		menuOptions.push({ label: 'Agregar a sala', action: a => openSelectRoom(a) })
	}
	if (permissions.indexOf('EDITAR USUARIO') !== -1)
		rowOptions.push({ label: 'Editar', action: row => setEditar(row) })
	if (permissions.indexOf('BORRAR USUARIO') !== -1)
		rowOptions.push({ label: 'Eliminar', action: row => setRowInfo({ del: true, row }) })

	const menuDeletedOptions = [
		{ label: 'Activar', action: selectedIds => setRowInfo({ activar: true, row: selectedIds }) }
	]
	const rowDeletedOptions = [ { label: 'Activar', action: row => setRowInfo({ activar: true, row: [ row.id ] }) } ]

	return (
		<Box className={classes.root}>
			<Box display='flex' alignItems='center'>
				<Button
					className={classes.nuevoButton}
					variant='contained'
					onClick={() => setRowInfo({ ...rowInfo, nuevo: true, row: {}, id: undefined })}
				>
					Nuevo
				</Button>
				<ButtonGroup style={{ marginRight: 15 }} color='primary' aria-label='outlined primary button group'>
					<Button
						onClick={() => setTipo('activos')}
						className={tipo === 'activos' ? classes.selected : undefined}
					>
						Activos
					</Button>
					<Button
						onClick={() => setTipo('eliminados')}
						className={tipo === 'eliminados' ? classes.selected : undefined}
					>
						Eliminados
					</Button>
				</ButtonGroup>
				<ButtonGroup color='primary' aria-label='outlined primary button group'>
					<Button
						onClick={() => setRol({ id: -1, name: 'todos' })}
						className={rol.id === -1 ? classes.selected : undefined}
					>
						Todos
					</Button>
					{roles.map((r, i) => (
						<Button
							key={'butt-' + i}
							onClick={() => setRol(r)}
							className={rol.id === r.id ? classes.selected : classes.notSelected}
						>
							{r.name}
						</Button>
					))}
				</ButtonGroup>
			</Box>
			<GenericTable
				search={search}
				cols={cols}
				data={data}
				menuOptions={tipo === 'eliminados' ? menuDeletedOptions : menuOptions.length > 0 ? menuOptions : null}
				rowOptions={tipo === 'eliminados' ? rowDeletedOptions : rowOptions.length > 0 ? rowOptions : null}
			/>
			<Dialog open={selectRoom.open} fullWidth maxWidth='sm'>
				<DialogTitle>Seleccionar sala</DialogTitle>
				<Divider />
				<DialogContent style={{ maxHeight: '50vh' }}>
					{selectRoom.loading ? (
						<CircularProgress color='primary' />
					) : (
						<List className={classes.list}>
							{selectRoom.rooms.map((r, i) => (
								<ListItem
									key={'li-' + i}
									className={selectRoom.roomId === r.uid ? classes.selectedItem : classes.listItem}
									button
									selected={selectRoom.roomId === r.uid}
									onClick={_ => setSelectRoom({ ...selectRoom, roomId: r.uid })}
								>
									<ListItemText key={'lit-' + i} primary={r.name} />
								</ListItem>
							))}
						</List>
					)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						onClick={() =>
							setSelectRoom({ open: false, rooms: [], roomId: -1, selectedIds: [], loading: false })}
					>
						Cancelar
					</Button>
					<Button
						color='primary'
						variant='contained'
						disabled={selectRoom.loading || selectRoom.roomId < 1}
						onClick={addToRoom}
					>
						Agregar
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog open={rowInfo.edit || rowInfo.del || rowInfo.activar || rowInfo.nuevo} fullWidth maxWidth='sm'>
				<DialogTitle>
					{rowInfo.nuevo ? 'Nuevo' : rowInfo.edit ? 'Editar' : rowInfo.activar ? 'Activar' : 'Eliminar'}{' '}
					Usuario
				</DialogTitle>
				<Divider />
				<DialogContent style={{ maxHeight: '50vh' }}>
					{rowInfo.activar ? (
						<DialogContentText>
							¿Desea activar {rowInfo.row.length} usuario{rowInfo.row.length > 1 ? 's' : ''}?
						</DialogContentText>
					) : rowInfo.del ? (
						<DialogContentText>¿Desea eliminar al usuario {rowInfo.row.fullName}?</DialogContentText>
					) : (
						<React.Fragment>
							<TextField
								className={classes.textField}
								variant='filled'
								name='username'
								value={rowInfo.row.username || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='Username'
								fullWidth
								error={rowInfo.error && !rowInfo.row.username}
							/>
							<TextField
								className={classes.textField}
								variant='filled'
								name='first_name'
								value={rowInfo.row.first_name || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='Nombre'
								fullWidth
								error={rowInfo.error && !rowInfo.row.first_name}
							/>
							<TextField
								className={classes.textField}
								variant='filled'
								name='last_name'
								value={rowInfo.row.last_name || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='Apellido'
								fullWidth
								error={rowInfo.error && !rowInfo.row.last_name}
							/>
							<TextField
								className={classes.textField}
								variant='filled'
								name='dni'
								value={rowInfo.row.dni || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='DNI'
								fullWidth
								error={rowInfo.error && !rowInfo.row.dni}
							/>
							{/* 							<TextField
								className={classes.textField}
								variant='filled'
								name='email'
								value={rowInfo.row.email || ''}
								onChange={e => setRowInfo({ ...rowInfo, row: { ...rowInfo.row, [e.target.name]: e.target.value } })}
								label='Email'
								type='email'
								fullWidth
								error={rowInfo.error && rowInfo.row.email}
							/> */}
							<TextField
								className={classes.textField}
								variant='filled'
								name='roleType'
								value={rowInfo.row.roleType || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='Rol'
								fullWidth
								error={rowInfo.error && !rowInfo.row.roleType}
								select
							>
								{roles.map(r => (
									<MenuItem key={r.id} value={r.acronym}>
										{r.name}
									</MenuItem>
								))}
							</TextField>
							{/* 							<MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
								<DatePicker
									className={classes.textField}
									label='Fecha de nacimiento'
									format='dd/MM/yyyy'
									value={rowInfo.row.birthday || new Date()}
									maxDate={new Date()}
									onChange={e => setRowInfo({ ...rowInfo, row: { ...rowInfo.row, birthday: e } })}
									cancelLabel='Atras'
									okLabel='Seleccionar'
									color='primary'
									inputVariant='filled'
									fullWidth
								/>
							</MuiPickersUtilsProvider> */}
							{rowInfo.edit ? <Divider stylse={{ margin: '20px 0px' }} /> : null}
							{rowInfo.edit ? (
								<DialogContentText>
									Cambiar contraseña (no completar para no cambiarla)
								</DialogContentText>
							) : null}
							<TextField
								className={classes.textField}
								variant='filled'
								name='password'
								value={rowInfo.row.password || ''}
								onChange={e =>
									setRowInfo({
										...rowInfo,
										row: { ...rowInfo.row, [e.target.name]: e.target.value }
									})}
								label='Nueva contraseña'
								type='password'
								fullWidth
								error={rowInfo.error && !rowInfo.row.password && rowInfo.nuevo}
							/>
							{rowInfo.edit ? (
								<TextField
									className={classes.textField}
									variant='filled'
									name='password2'
									value={rowInfo.row.password2 || ''}
									onChange={e =>
										setRowInfo({
											...rowInfo,
											row: { ...rowInfo.row, [e.target.name]: e.target.value }
										})}
									label='Confirmar contraseña'
									fullWidth
									type='password'
								/>
							) : null}
						</React.Fragment>
					)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={cleanRowInfo}>Cancelar</Button>
					<Button
						color='primary'
						variant='contained'
						onClick={
							rowInfo.edit || rowInfo.nuevo ? onSaveUser : rowInfo.activar ? onActiveUser : onDeleteUser
						}
					>
						{rowInfo.edit || rowInfo.nuevo ? 'Guardar' : rowInfo.activar ? 'Activar' : 'Eliminar'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
