import React, { useState } from 'react'
import { Box, makeStyles, AppBar, Button, Menu, MenuItem, Divider, Typography, useTheme } from '@material-ui/core'
import { ComputerRounded, HomeRounded, PersonRounded, WbSunnyRounded, NightsStayRounded, SettingsRounded, ExitToAppRounded, VpnKeyRounded } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'

import { toggleDarkMode } from '../../actions/theme/toggleDarkMode'
import { logout } from '../../actions/login/logout'
import { useHistory } from 'react-router-dom'
import { yellow, indigo } from '@material-ui/core/colors'
import ProfileDialog from '../resources/ProfileDialog'
import ChangePassDialog from '../resources/ChangePassDialog'
import { apiPut } from '../../api'
import { urlChangePass } from '../../api/urls'
import { showSnack } from '../../actions/Feedback/showSnack'

export const topBarHeight = 64

const useStyles = makeStyles(theme => ({
	root: {
		height: topBarHeight,
		width: '100%',
		padding: '0px 10%',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		position: 'static',
		boxShadow: '0 1px 0 0 rgba(0,0,0,.1)',
		backgroundColor: theme.palette.background.paper
	},
	options: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	optionButton: {
		margin: '0px 5px',
		textTransform: 'none'
	}
}))

export default function TopBar() {
	const classes = useStyles()
	const theme = useTheme()
	const login = useSelector(state => state.login)
	const userChangePass = useSelector(state => state.app.userChangePass)
	const darkChecked = useSelector(state => state.theme.darkChecked)
	const dispatch = useDispatch()
	const history = useHistory()
	const [ openMenu, setOpenMenu ] = useState(null)
	const [ openProfile, setOpenProfile ] = useState(false)
	const [ openPass, setOpenPass ] = useState(false)
    const logged = useSelector(state => state.login.logged)
    const img = useSelector(state => state.app.img)

	const onChangePass = data => {
		apiPut(urlChangePass, data).then(r => {
			if (r.status === 'OK') dispatch(showSnack('Contraseña cambiada con éxito', 'success'))
			else if(r.status === 'INCORRECT') dispatch(showSnack('La contraseña actual ingresada es incorrecta', 'error'))
			else dispatch(showSnack('Error al cambiar contraseña', 'error'))
		})
	}

	return (
		<AppBar color='default' className={classes.root}>
			<ProfileDialog data={login} open={openProfile} onClose={() => setOpenProfile(false)} />
			<ChangePassDialog open={openPass} onClose={() => setOpenPass(false)} onSubmit={onChangePass} />
			{img ? <img src={img} alt='app-icon' height='70%' /> : <ComputerRounded color='primary' />}
			{logged ? (
				<Box className={classes.options}>
					<Button className={classes.optionButton} onClick={() => history.push('/dashboard/home')} startIcon={<HomeRounded />}>
						Inicio
					</Button>
					<Button className={classes.optionButton} onClick={e => setOpenMenu(e.currentTarget)} startIcon={<PersonRounded color='primary' />}>
						{login && login.user ? login.user.fullName : ''}
					</Button>
					<Menu anchorEl={openMenu} open={Boolean(openMenu)} onClose={() => setOpenMenu(null)}>
						<MenuItem
							onClick={() => {
								setOpenProfile(true)
								setOpenMenu(null)
							}}
						>
							<PersonRounded fontSize='small' />
							<Typography style={{ marginLeft: 5 }} variant='inherit'>
								Perfil de usuario
							</Typography>
						</MenuItem>
						{userChangePass ? (
							<MenuItem
								onClick={() => {
									setOpenPass(true)
									setOpenMenu(null)
								}}
							>
								<VpnKeyRounded fontSize='small' />
								<Typography style={{ marginLeft: 5 }} variant='inherit'>
									Cambiar contraseña
								</Typography>
							</MenuItem>
						) : null}
						{login.permissions.indexOf('PANEL DE CONTROL') !== -1 ?<MenuItem
							onClick={() => {
								history.push('/dashboard/administration')
								setOpenMenu(null)
							}}
						>
							<SettingsRounded fontSize='small' />
							<Typography style={{ marginLeft: 5 }} variant='inherit'>
								Administración
							</Typography>
						</MenuItem> : null}
						<Divider />
						{darkChecked ? (
							<MenuItem onClick={() => dispatch(toggleDarkMode())}>
								{theme.palette.type === 'dark' ? (
									<WbSunnyRounded style={{ color: yellow[300] }} fontSize='small' />
								) : (
									<NightsStayRounded style={{ color: indigo[400] }} fontSize='small' />
								)}
								<Typography style={{ marginLeft: 5 }} variant='inherit'>
									Tema {theme.palette.type === 'dark' ? 'claro' : 'oscuro'}
								</Typography>
							</MenuItem>
						) : null}
						<Divider />
						<MenuItem
							onClick={() => {
								dispatch(logout())
								setOpenMenu(null)
							}}
						>
							<ExitToAppRounded color='error' fontSize='small' />
							<Typography style={{ marginLeft: 5 }} variant='inherit'>
								Salir
							</Typography>
						</MenuItem>
					</Menu>
				</Box>
			) : (
				<Box className={classes.options}>
					<Button className={classes.optionButton} variant='contained' color='primary' onClick={() => history.push('/login', {back: true, backUrl: window.location.toString()})}>
						Ingresar
					</Button>
{/* 					<Button className={classes.optionButton} variant='contained' color='primary' onClick={() => history.push('/register')}>
						Registrarse
					</Button> */}
				</Box>
			)}
		</AppBar>
	)
}
