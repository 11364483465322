import url from './server'

export const urlLogin = `${url}login`
export const urlUsers = `${url}users`
export const urlPermisos = `${url}permisos`
export const urlRoles = `${url}roles`
export const urlRooms = url+'rooms'
export const urlJoin = url+'join'
export const urlMembers = url+'members'
export const urlGetMeeting = url+'getMeeting'
export const urlMembersToRoom = id => urlMembers+'?toRoom='+id
export const urlMembersGetRoomUsers = id => urlMembers+'?roomId='+id
export const urlMembersDeleteUser = id => urlMembers+'?fromRoom='+id
export const urlSettings = url+'settings'
export const urlChangePass = url+'changePassword'
export const urlListRecording = value => urlRooms+`?listRecording=${value}`
export const urlDeleteRecording = urlRooms+'?deleteRecording=true'

export const invitationLink = window.location.origin+'/join/'