import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { format } from 'date-fns'
import { Dialog, DialogTitle, Divider, DialogContent, Typography, DialogActions, Button } from '@material-ui/core'

import GenericTable from '../../resources/GenericTable'
import { apiGetWithParams, apiPut, apiDelete } from '../../../api'
import { urlRooms, urlListRecording, urlDeleteRecording } from '../../../api/urls'
import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'

export default function Recordings(props) {
	const [ data, setData ] = useState([])
	const [ doFetch, setDoFetch ] = useState(0)
	const [ delRecords, setDelRecords ] = useState({ open: false, ids: [] })
	const dispatch = useDispatch()
	const { room, search } = props
	var cols = [
		{ id: 'name', search: true, numeric: false, disablePadding: false, label: 'Nombre' },
		{ id: 'length', search: true, numeric: false, disablePadding: false, label: 'Duración' },
		{ id: 'fecha', search: false, numeric: false, disablePadding: false, label: 'Fecha' },
		{ id: 'participants', search: false, numeric: false, disablePadding: false, label: 'Partcipantes' }
	]

	useEffect(
		() => {
            dispatch(startLoading())
			apiGetWithParams(urlRooms, { getRecordings: room ? room.uid : 'allRooms' }).then(r => {
				if (r.status === 'OK')
					setData(
						r.data.map(e => ({
							...e,
							fecha: format(new Date(e.date), 'dd/MM/yyyy - HH:mm') + ' hs',
							length: e.length > 0 ? `${e.length} min` : '< 1 min'
						}))
					)
                else dispatch(showSnack('Error al recuperar las grabaciones', 'error'))
                dispatch(endLoading())
			})
		},
		[ setData, room, dispatch, doFetch ]
	)

	const changeVisibilityRecord = r => {
        dispatch(startLoading())
		apiPut(urlListRecording(!r.listed), { id: r.id }).then(r => {
			if (r.status === 'OK') {
				dispatch(showSnack('Cambios realizados con éxito', 'success'))
				setDoFetch(doFetch + 1)
            } else dispatch(showSnack('Algo salió mal', 'error'))
            dispatch(endLoading())
		})
	}

	const delRecord = ids => {
        dispatch(startLoading())
		apiDelete(urlDeleteRecording, { ids }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				dispatch(showSnack(ids.length === 1 ? 'Grabación elimnada con éxito' : 'Grabaciones eliminadas con éxito', 'success'))
            } else dispatch(showSnack('Algo salió mal', 'error'))
            dispatch(endLoading())
		})
    }
    
    const verRecording = r => {
        if (r.url) window.location.replace(r.url)
        else dispatch(showSnack('Grabación no disponible', 'error'))
    }

    const verOption = [ {label: 'Ver', action: r => verRecording(r)} ]

	const allRowOptions = [...verOption, { label: 'Publicar/Ocultar', action: r => changeVisibilityRecord(r) }, { label: 'Eliminar', action: r => setDelRecords({ open: true, ids: [ r.id ] }) } ]

	let allMenuOptions = [ { label: 'Eliminar', action: ids => setDelRecords({ open: true, ids }) } ]

	if (!room || room.owner) cols.push({ id: 'listed', search: false, numeric: false, disablePadding: false, label: 'Pública', boolean: true })

	return (
		<React.Fragment>
			<Dialog open={delRecords.open} fullWidth maxWidth='xs'>
				<DialogTitle>Confirmar acción</DialogTitle>
				<Divider />
				<DialogContent>
					<Typography paragraph>¿Está seguro que desea eliminar la grabación?</Typography>
					<Typography color='secondary'>Advertencia: No se podrá deshacer la acción</Typography>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => setDelRecords({ open: false, id: 0 })}>Cancelar</Button>
					<Button variant='contained' color='secondary' onClick={() => delRecord(delRecords.ids)}>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
			<GenericTable
				search={search}
				cols={cols}
				data={data}
				menuOptions={!room ? allMenuOptions : room.owner ? allMenuOptions : null}
				rowOptions={!room ? allRowOptions : room.owner ? allRowOptions : null}
			/>
		</React.Fragment>
	)
}
