import React, { useState } from 'react'
import {
	Box,
	makeStyles,
	Paper,
	Typography,
	TextField,
	Divider,
	Button,
	InputAdornment,
	CircularProgress
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { AlternateEmailRounded, VpnKeyRounded } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { topBarHeight } from '../TopBar'
import { setLogin } from '../../actions/login/setLogin'
import { apiPost } from '../../api'
import { urlLogin } from '../../api/urls'
import { showSnack } from '../../actions/Feedback/showSnack'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: 'calc(100vh - ' + topBarHeight + 'px)',
		flexDirection: 'column',
		width: '100%'
	},
	paper: {
		minHeight: '25%',
		minWidth: '30%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	inputBox: {
		padding: 20,
		flex: 1,
		width: 'calc(100% - 40px)',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	textfield: {
		margin: '10px 0px'
	},
	loginButton: {
		margin: '20px auto'
	},
	cardTitle: {
		width: '100%',
		margin: '20px 0px'
	}
}))

export default function Login() {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const logged = useSelector(state => state.login.logged)
	const [ username, setUsername ] = useState('')
	const [ password, setPassword ] = useState('')
	const [ loading, setLoading ] = useState(false)

	const login = () => {
		setLoading(true)
		apiPost(urlLogin, { username, password }).then(r => {
			if (r.status === 'OK') {
				dispatch(setLogin(r.data))
				history.push('/dashboard/home')
			} else if (r.status === 'INCORRECT') dispatch(showSnack('Usuario o contraseña incorrectos', 'info'))
			else dispatch(showSnack('Algo salio mal', 'error'))
			setLoading(false)
		})
	}

	const onKeyPress = e => {
		if (e.which === 13 || e.keyCode === 13) {
			login()
			return false
		} else return true
	}
	if (logged) {
		if (history.location.state?.back) history.goBack()
		else history.push('/')
	}

	return (
		<Box className={classes.root}>
			<Paper className={classes.paper}>
				<Typography className={classes.cardTitle} align='center' variant='h6'>
					Ingresar a tu cuenta
				</Typography>
				<Divider style={{ width: '100%' }} />
				<Box className={classes.inputBox}>
					<TextField
						placeholder='Usuario'
						className={classes.textfield}
						name='username'
						variant='filled'
						value={username}
						onKeyPress={onKeyPress}
						onChange={e => setUsername(e.target.value)}
						color='primary'
						required
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<AlternateEmailRounded />
								</InputAdornment>
							)
						}}
						fullWidth
					/>
					<TextField
						placeholder='Contraseña'
						className={classes.textfield}
						name='password'
						variant='filled'
						value={password}
						onChange={e => setPassword(e.target.value)}
						onKeyPress={onKeyPress}
						color='primary'
						required
						type='password'
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<VpnKeyRounded />
								</InputAdornment>
							)
						}}
						fullWidth
					/>
				</Box>
				<Divider style={{ width: '100%' }} />
				<Button
					className={classes.loginButton}
					variant='contained'
					color='primary'
					onClick={login}
					disabled={loading}
				>
					{!loading ? 'Ingresar' : <CircularProgress style={{ color: 'white' }} />}
				</Button>
			</Paper>
		</Box>
	)
}
