import { SET_SETTINGS } from "../constants"

export const app = (state = {  }, a) => {
	switch (a.type) {
		case SET_SETTINGS:
            document.title = a.payload.app?.siteTitle? a.payload.app.siteTitle : 'GO AHEAD'
			return {...state, ...a.payload.app}
		default:
			return state
	}
}
