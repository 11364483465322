import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Home from './Home'
import Organization from './Administration'
import { useSelector } from 'react-redux'

export default function Dashboard() {
	const login = useSelector(state => state.login)

	return (
		<Switch>
			<Route path='/dashboard/home'>
				<Home />
			</Route>
			{login.permissions.indexOf('PANEL DE CONTROL') !== -1 ?<Route path='/dashboard/administration'>
				<Organization />
			</Route> : null}
		</Switch>
	)
}
