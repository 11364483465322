import React, { useEffect, useState } from 'react'
import './App.css'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { ThemeProvider, createMuiTheme, Box, Snackbar, SnackbarContent, IconButton, CircularProgress, Backdrop } from '@material-ui/core'
import { red, green, blue } from '@material-ui/core/colors'

import Login from './Components/Login'
import Dashboard from './Components/Dashboard'
import TopBar from './Components/TopBar'
import Welcome from './Components/Welcome'
import Register from './Components/Register'

import { closeSnack } from './actions/Feedback/closeSnack'
import { Close } from '@material-ui/icons'
import { apiPost, apiGet } from './api'
import { setLogin } from './actions/login/setLogin'
import { urlLogin, urlSettings } from './api/urls'
import { showSnack } from './actions/Feedback/showSnack'
import { setSettings } from './actions/app/setSettings'
import Join from './Components/Join'

function App() {
	const logged = useSelector(state => state.login.logged)
	const loading = useSelector(state => state.feedback.loading)
	const prefersDarkMode = useSelector(state => state.theme.prefersDarkMode)
	const dispatch = useDispatch()
	const [ open, text, type ] = useSelector(state => [ state.feedback.open, state.feedback.text, state.feedback.type ])
	const [ loading1, setLoading1 ] = useState(true)
	const [ loading2, setLoading2 ] = useState(true)

	const lightPrimary = useSelector(state => state.theme.light)
	const darkPrimary = useSelector(state => state.theme.dark)
	const lightSecondary = red[600]
    const darkSecondary = red[300]
    
	const theme = createMuiTheme({
		palette: {
			type: prefersDarkMode ? 'dark' : 'light',
			primary: { main: !prefersDarkMode ? lightPrimary : darkPrimary },
			secondary: { main: !prefersDarkMode ? lightSecondary : darkSecondary },
			background: {
				default: prefersDarkMode ? '#121212' : '#fafafa',
				level1: prefersDarkMode ? '#212121' : '#fff',
				level2: prefersDarkMode ? '#313131' : '#f5f5f5',
				paper: prefersDarkMode ? '#424242' : '#fff'
			},
			success: { main: prefersDarkMode ? green[300] : green[600] },
			error: { main: prefersDarkMode ? red[300] : red[600] }
		},
		props: {
			MuiButton: {
				disableElevation: true,
				style: { textTransform: 'none' }
			}
		}
	})

	useEffect(
		() => {
			const token = localStorage.getItem('token')
			apiGet(urlSettings).then(r => {
				if (r.status === 'OK') dispatch(setSettings(r.data))
				else dispatch(showSnack('Error al obtener datos de la plataforma', 'Error'))
				setLoading2(false)
			})
			if (token)
				apiPost(urlLogin, { token })
					.then(r => {
						if (r.status === 'OK') {
							dispatch(setLogin(r.data))
						}
						setLoading1(false)
					})
					.catch(_ => setLoading1(false))
			else setLoading1(false)
		},
		[ dispatch ]
	)

	return (
		<ThemeProvider theme={theme}>
			<Snackbar
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				open={open}
				autoHideDuration={5000}
				onClose={() => dispatch(closeSnack())}
				message={text}
			>
				<SnackbarContent
					style={{
						backgroundColor: type === 'error' ? theme.palette.error.main : type === 'success' ? theme.palette.success.main : blue[900]
					}}
					message={text}
					action={
						<React.Fragment>
							<IconButton size='small' aria-label='close' color='inherit' onClick={() => dispatch(closeSnack())}>
								<Close fontSize='small' />
							</IconButton>
						</React.Fragment>
					}
				/>
			</Snackbar>
			<Backdrop style={{zIndex: 1600, color: '#fff'}} open={Boolean(loading)}>
				<CircularProgress color='inherit' />
			</Backdrop>
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					background: theme.palette.type === 'dark' ? theme.palette.background.default : 'linear-gradient(to bottom, #daeafa, #fff)',
					minHeight: '100vh'
				}}
			>
				{!loading2 ? <TopBar /> : null}
				{!loading1 ? (
					<Switch>
						<Route exact path='/'>
							<Welcome />
						</Route>
						<Route path='/login'>
							<Login />
						</Route>
						<Route path='/register'>
							<Register />
						</Route>
						<Route path='/join/:uid'>
							<Join />
						</Route>
						{logged ? (
							<Route path='/dashboard'>
								<Dashboard />
							</Route>
						) : (
							<Redirect to='/' />
						)}
					</Switch>
				) : (
					<Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flex: 1, width: '100%' }}>
						<CircularProgress color='primary' />
					</Box>
				)}
			</Box>
		</ThemeProvider>
	)
}

export default App
