import React, { useEffect, useState } from 'react'
import {
	Box,
	makeStyles,
	ListItem,
	ListItemText,
	List,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	Button,
	DialogActions,
	ListItemSecondaryAction,
	IconButton,
	Divider
} from '@material-ui/core'
import DeleteRounded from '@material-ui/icons/DeleteRounded'
import { apiGet, apiPost, apiPut, apiDelete } from '../../../api'
import { urlRoles, urlPermisos } from '../../../api/urls'
import { useDispatch, useSelector } from 'react-redux'
import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'
import TransferList from '../../resources/TransferList'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%'
	},
	roleList: {
		flex: 1,
		marginRight: 15
	},
	transferWrapper: {
		flex: 4,
		display: 'flex',
		flexDirection: 'column'
	},
	selectedItem: {
		borderRadius: 4,
		color: theme.palette.primary.main
	},
	listItem: {
		color: theme.palette.type === 'dark' ? 'white' : 'rgba(0,0,0,0.54)',
		borderRadius: 4
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	},
	editButton: {
		marginLeft: 'auto'
	},
	newButton: {
		marginTop: 10
	}
}))

export default function Roles() {
    const classes = useStyles()
    const permissions = useSelector(state=>state.login.permissions)
	const [ doFetch, setDoFetch ] = useState(0)
	const [ roles, setRoles ] = useState([])
	const [ permisos, setPermisos ] = useState([])
	const [ openNewRol, setOpenNewRol ] = useState({ open: false, name: '', acronym: '' })
	const [ permisosDisponibles, setPermisosDisponibles ] = useState([])
	const [ rol, setRol ] = useState({})
	const dispatch = useDispatch()

	useEffect(
		() => {
            dispatch(startLoading())
			apiGet(urlRoles).then(r => {
				if (r.status === 'OK') setRoles(r.data)
                else dispatch(showSnack('Error al obtener la lista de roles', 'error'))
                dispatch(endLoading())
            })
            dispatch(startLoading())
			apiGet(urlPermisos).then(r => {
				if (r.status === 'OK') setPermisos(r.data.map(p => p.name))
                else dispatch(showSnack('Error al obtener la lista de permisos', 'error'))
                dispatch(endLoading())
			})
		},
		[ setRoles, dispatch, setPermisos, doFetch ]
	)

	const setRolPermisos = permisos => {
		setRol({ ...rol, permisos })
	}

	const onCrearRol = () => {
        dispatch(startLoading())
		apiPost(urlRoles, { name: openNewRol.name, acronym: openNewRol.acronym }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				dispatch(showSnack('Rol creado con éxito', 'success'))
				setOpenNewRol({ open: false, nombre: '', acronym: '' })
			} else if (r.status === 'ALREADY_EXISTS') dispatch(showSnack('Nombre o acrónimo ya existente', 'error'))
            else dispatch(showSnack('Error al crear rol', 'error'))
            dispatch(endLoading())
		})
	}

	const onEditRol = () => {
        dispatch(startLoading())
		apiPut(urlRoles, { ...rol }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Rol modificado con éxito', 'success'))
				setRol({})
			} else if (r.status === 'ALREADY_EXISTS') dispatch(showSnack('Nombre o acrónimo ya existente', 'error'))
            else dispatch(showSnack('Error al modificar rol', 'error'))
            dispatch(endLoading())
		})
	}

	const onDelRol = r => {
        dispatch(startLoading())
		apiDelete(urlRoles, { rolId: r.id }).then(r => {
			if (r.status === 'OK') {
				setDoFetch(doFetch + 1)
				dispatch(showSnack('Rol eliminado con éxito', 'success'))
				setRol({})
            } else dispatch(showSnack('Error al eliminar rol', 'error'))
            dispatch(endLoading())
		})
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.roleList}>
				<List style={{ padding: 0 }}>
					{roles.map(r => (
						<ListItem
							button
							key={'li-' + r.id}
							selected={rol.id === r.id}
							className={rol.id === r.id ? classes.selectedItem : classes.listItem}
							onClick={() => {
                                if (permissions.indexOf('MODIFICAR ROL') === -1) return 
								setPermisosDisponibles(permisos.filter(p => r.permisos.indexOf(p) === -1))
								setRol(r)
							}}
						>
							<ListItemText key={'lit-' + r.id} primary={r.name} />
							{r.name.toLowerCase() !== 'admin' && permissions.indexOf('BORRAR ROL') !== -1 ?<ListItemSecondaryAction key={'lisa-' + r.id}>
								<IconButton key={'ib-' + r.id} color='secondary' onClick={() => setRol({ ...r, eliminar: true })}>
									<DeleteRounded key={'dr-' + r.id} />
								</IconButton>
							</ListItemSecondaryAction>:null}
						</ListItem>
					))}
					{permissions.indexOf('CREAR ROL') !== -1? <Button
						className={classes.newButton}
						variant='outlined'
						color='primary'
						onClick={() => {
							setRol({})
							setOpenNewRol({ open: true, name: '', acronym: '' })
						}}
						fullWidth
					>
						Nuevo rol
					</Button> :null}
				</List>
			</Box>
			<Box className={classes.transferWrapper}>
				{rol.name ? (
					<React.Fragment>
						<Typography paragraph>Editar rol</Typography>
						<TextField
							margin='dense'
							variant='filled'
							name='name'
							value={rol.name}
							label='Nombre'
							onChange={e => setRol({ ...rol, [e.target.name]: e.target.value })}
						/>
						<TextField
							margin='dense'
							variant='filled'
							name='acronym'
							value={rol.acronym}
							label='Acrónimo'
							onChange={e => (e.target.value.length > 4 ? '' : setRol({ ...rol, [e.target.name]: e.target.value }))}
							helperText='Nombre abreviado del rol, por ejemplo: Alumno - AL'
						/>
						<TransferList
							left={rol.permisos}
							right={permisosDisponibles}
							setLeft={setRolPermisos}
							setRight={setPermisosDisponibles}
							leftName='Permisos asignados'
							rightName='Disponibles'
						/>
						<Button className={classes.editButton} variant='contained' color='primary' onClick={onEditRol}>
							Guardar cambios
						</Button>
					</React.Fragment>
				) : (
					<Typography>{permissions.indexOf('MODIFICAR ROL') !== -1? 'Seleccione un rol para modificar' : 'No tiene permisos para editar los roles'}</Typography>
				)}
			</Box>
			<Dialog open={openNewRol.open || rol.eliminar}>
				<DialogTitle>{rol.eliminar ? 'Eliminar rol' : 'Nuevo Rol'}</DialogTitle>
				<Divider />
				<DialogContent className={classes.content}>
					{rol.eliminar ? (
						<Typography>¿Está seguro que desea eliminar el rol {rol.name}?</Typography>
					) : (
						<React.Fragment>
							<TextField
								margin='dense'
								name='name'
								value={openNewRol.name}
								onChange={e => setOpenNewRol({ ...openNewRol, name: e.target.value })}
								label='name'
								variant='filled'
								fullWidth
							/>
							<TextField
								margin='dense'
								name='acronym'
								value={openNewRol.acronym}
								onChange={e => (e.target.value.length > 4 ? '' : setOpenNewRol({ ...openNewRol, acronym: e.target.value }))}
								label='Acrónimo'
								variant='filled'
								helperText='Nombre abreviado del rol, por ejemplo: Alumno - AL'
								fullWidth
							/>
						</React.Fragment>
					)}
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button
						color='secondary'
						onClick={() => {
							setRol({ ...rol, eliminar: false })
							setOpenNewRol({ open: false, name: '' })
						}}
					>
						Salir
					</Button>
					<Button color='primary' variant='contained' onClick={rol.eliminar ? () => onDelRol(rol) : onCrearRol}>
						{rol.eliminar ? 'Confirmar' : 'Crear'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
