export const SET_LOGIN = 'setLogin'
export const LOGOUT = 'logout'
export const TOGGLE_DARK = 'toggleDarkMode'
export const SHOW_SNACK = 'showSnack'
export const CLOSE_SNACK = 'closeSnack'
export const CHANGE_COLOR = 'changeColor'
export const SET_SETTINGS = 'setSettings'
export const START_LOADING = 'startLoading'
export const END_LOADING = 'endLoading'

export const isAlfaNum = /^[A-Za-zÀ-ÖØ-öø-ÿ0-9.]*$/
