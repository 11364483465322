import React, { useState, useEffect } from 'react'
import {
	Box,
	makeStyles,
	Typography,
	TextField,
	IconButton,
	Button,
	useTheme,
	createMuiTheme,
	ThemeProvider,
	CardHeader,
	Card,
	CardContent,
	Switch,
	CardActions,
	FormControlLabel,
	Tooltip,
	Divider,
	MenuItem
} from '@material-ui/core'
import { SaveRounded, CheckRounded, LinkOffRounded, LinkRounded } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import {
	blue,
	lightBlue,
	indigo,
	orange,
	red,
	green,
	cyan,
	purple,
	pink,
	grey,
	deepOrange,
	deepPurple,
	brown,
	amber,
	blueGrey,
	lightGreen,
	lime,
	teal,
	yellow
} from '@material-ui/core/colors'

import { apiGet, apiPost } from '../../../api'
import { urlSettings } from '../../../api/urls'
import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'
import { setSettings } from '../../../actions/app/setSettings'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column'
	},
	textfield: {
		marginRight: 5,
		marginBottom: 15
	},
	content: {
		display: 'flex',
		alignItems: 'flex-start'
	},
	colorButton: {
		marginRight: 15
	},
	saveButton: {
		marginLeft: 'auto',
		marginTop: 15
	},
	platConfig: {
		display: 'flex',
		flexDirection: 'column',
		width: '50%'
	}
}))

const lightAccent = 500
const darkAccent = 300

const colors = [
	blue,
	lightBlue,
	cyan,
	teal,
	green,
	lightGreen,
	lime,
	yellow,
	amber,
	orange,
	deepOrange,
	red,
	pink,
	purple,
	deepPurple,
	indigo,
	blueGrey,
	brown,
	grey
]

export default function ConfigWeb() {
    const classes = useStyles()
    const permissions = useSelector(state => state.login.permissions)
	const theme = useTheme()
    const imgOld = useSelector((state) => state.app.img)
    const siteTitleOld = useSelector(state => state.app.siteTitle)
    const subtitleOld = useSelector(state => state.app.subtitle)
    const [ siteTitle, setSiteTitle ] = useState(siteTitleOld || '')
    const [ subtitle, setSubtitle ] = useState(subtitleOld || 'clases en vivo')
	const [ img, setImg ] = useState(imgOld || '')
	const [ doFetch, setDoFetch ] = useState(0)
	const [ darkChecked, setDarkChecked ] = useState(useSelector((state) => state.theme.darkChecked))
	const [ dark, setDark ] = useState(useSelector((state) => state.theme.dark))
	const [ light, setLight ] = useState(useSelector((state) => state.theme.light))
	const [ app, setApp ] = useState(useSelector((state) => state.app))
	const cl = colors.filter((c) => c[lightAccent] === light)
	const cd = colors.filter((c) => c[darkAccent] === dark)
	const [ link, setLink ] = useState(cl.length === 1 && cd.length === 1 ? cl[0][400] === cd[0][400] : false)
	const dispatch = useDispatch()

	useEffect(
		() => {
			dispatch(startLoading())
			apiGet(urlSettings).then((r) => {
				if (r.status === 'OK') dispatch(setSettings(r.data))
				else dispatch(showSnack('Error al obtener los datos de la plataforma', 'error'))
				dispatch(endLoading())
			})
		},
		[ dispatch, doFetch ]
	)

	const changeImage = () => {
		dispatch(startLoading())
		apiPost(urlSettings, { app: { img } }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Imagen actualizada correctamente', 'Success'))
			} else dispatch(showSnack('Error al actualizar imagen', 'Error'))
			dispatch(endLoading())
		})
    }
    
    const changeTitle = () => {
        dispatch(startLoading())
		apiPost(urlSettings, { app: { siteTitle } }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Nombre del sitio actualizado correctamente', 'Success'))
			} else dispatch(showSnack('Error al actualizar el nombre del sitio', 'Error'))
			dispatch(endLoading())
		})
    }
    
    const changeSubtitle = () => {
        dispatch(startLoading())
		apiPost(urlSettings, { app: { subtitle } }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Subtitulo del sitio actualizado correctamente', 'Success'))
			} else dispatch(showSnack('Error al actualizar el subtitulo del sitio', 'Error'))
			dispatch(endLoading())
		})
    }

	const saveTheme = () => {
		dispatch(startLoading())
		apiPost(urlSettings, { theme: { dark, light, darkChecked } }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Tema actualizado correctamente', 'Success'))
			} else dispatch(showSnack('Error al actualizar el tema', 'Error'))
			dispatch(endLoading())
		})
	}

	const saveSettings = () => {
		dispatch(startLoading())
		apiPost(urlSettings, { app }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(doFetch - 1)
				dispatch(showSnack('Configuración actualizada correctamente', 'Success'))
			} else dispatch(showSnack('Error al actualizar la configuración', 'Error'))
			dispatch(endLoading())
		})
	}

	const renderPaleta = (accent, set, setOther, otherAccent) => {
		return colors.map((c) => (
			<IconButton
				key={c[lightAccent]}
				style={{ backgroundColor: c[accent], margin: '0px 2px' }}
				onClick={() => {
					set(c[accent])
					if (link) setOther(c[otherAccent])
				}}
			>
				{c[accent] === dark || c[accent] === light ? <CheckRounded key={c[400]} /> : null}
			</IconButton>
		))
	}

	const darkTheme = createMuiTheme({
		...theme,
		palette: {
			type: 'dark',
			primary: { main: dark }
		}
	})

	const lightTheme = createMuiTheme({
		...theme,
		palette: {
			type: 'light',
			primary: { main: light }
		}
    })
    
    if (permissions.indexOf('SITE CONFIG') === -1) return <Typography>No tiene permiso para ver esta página</Typography>

	return (
		<Box className={classes.root}>
			<Typography paragraph>Nombre del sitio</Typography>
			<TextField
				className={classes.textfield}
				variant='filled'
				name='siteTitle'
				value={siteTitle}
				onChange={(e) => setSiteTitle(e.target.value)}
				fullWidth
				InputProps={{
					endAdornment:
						siteTitle === siteTitleOld ? null : (
							<IconButton color='primary' onClick={changeTitle}>
								<SaveRounded />
							</IconButton>
						)
				}}
			/>
			<Typography paragraph>Subtitulo del sitio</Typography>
			<TextField
				className={classes.textfield}
				variant='filled'
				name='subtitle'
				value={subtitle}
				onChange={(e) => setSubtitle(e.target.value)}
				fullWidth
				InputProps={{
					endAdornment:
						subtitle === subtitleOld ? null : (
							<IconButton color='primary' onClick={changeSubtitle}>
								<SaveRounded />
							</IconButton>
						)
				}}
			/>
			<Typography paragraph>Icono</Typography>
			<TextField
				className={classes.textfield}
				variant='filled'
				name='img'
				value={img}
				onChange={(e) => setImg(e.target.value)}
				fullWidth
				label='Imagen personalizada'
				helperText='Cambiar la imagen personalizada que aparece en la esquina superior izquierda'
				InputProps={{
					endAdornment:
						img === imgOld ? null : (
							<IconButton color='primary' onClick={changeImage}>
								<SaveRounded />
							</IconButton>
						)
				}}
			/>
			<Typography paragraph>Color principal</Typography>
			<ThemeProvider theme={lightTheme}>
				<Card>
					<CardHeader
						disableTypography
						title={
							<Box display='flex' flexDirection='row' alignItems='center'>
								<Typography>Tema claro </Typography>
								{renderPaleta(lightAccent, setLight, setDark, darkAccent)}
							</Box>
						}
					/>
					<CardContent className={classes.content}>
						<Button className={classes.colorButton} color='primary' variant='contained'>
							Texto de ejemplo
						</Button>
						<Button className={classes.colorButton} color='primary' variant='outlined'>
							Texto de ejemplo
						</Button>
						<Button className={classes.colorButton} color='primary'>
							Texto de ejemplo
						</Button>
					</CardContent>
				</Card>
			</ThemeProvider>
			<Box style={{ margin: '10px 0px' }}>
				<Tooltip title={!link ? 'Mantener color entre temas' : 'Usar colores diferentes'}>
					<IconButton
						color='primary'
						onClick={() => {
							if (!link) {
								const color = colors.filter((c) => c[lightAccent] === light)
								if (color.length === 1) setDark(color[0][darkAccent])
							}
							setLink(!link)
						}}
					>
						{!link ? <LinkOffRounded /> : <LinkRounded />}
					</IconButton>
				</Tooltip>
			</Box>
			<ThemeProvider theme={darkTheme}>
				<Card>
					<CardHeader
						disableTypography
						title={
							<Box display='flex' flexDirection='row' alignItems='center'>
								<Typography>Tema oscuro</Typography>
								{!link ? renderPaleta(darkAccent, setDark, setLight, lightAccent) : null}
							</Box>
						}
					/>
					<CardContent className={classes.content}>
						<Button className={classes.colorButton} color='primary' variant='contained'>
							Texto de ejemplo
						</Button>
						<Button className={classes.colorButton} color='primary' variant='outlined'>
							Texto de ejemplo
						</Button>
						<Button className={classes.colorButton} color='primary'>
							Texto de ejemplo
						</Button>
					</CardContent>
					<CardActions>
						<FormControlLabel
							control={
								<Switch
									color='primary'
									checked={darkChecked}
									onChange={(e) => setDarkChecked(e.target.checked)}
									name='darkChecked'
								/>
							}
							label='Habilitar el modo oscuro en la plataforma'
							labelPlacement='end'
						/>
					</CardActions>
				</Card>
			</ThemeProvider>
			<Button className={classes.saveButton} variant='contained' color='primary' onClick={saveTheme}>
				Guardar cambios
			</Button>
			<Divider style={{ margin: '15px 0px' }} />
			<Typography paragraph>Configuración de la plataforma</Typography>
			<Box className={classes.platConfig}>
				<FormControlLabel
					control={
						<Switch
							color='primary'
							checked={app.authRoom || false}
							onChange={(e) => setApp({ ...app, [e.target.name]: e.target.checked })}
							name='authRoom'
						/>
					}
					label='Requerir autenticación para hacer uso de las salas'
					labelPlacement='end'
				/>
{/* 				<FormControlLabel
					control={
						<Switch
							color='primary'
							checked={app.userShareRoom || false}
							onChange={(e) => setApp({ ...app, [e.target.name]: e.target.checked })}
							name='userShareRoom'
						/>
					}
					label='Permitir a los usuarios compartir link a la sala'
					labelPlacement='end'
				/> */}
				<FormControlLabel
					control={
						<Switch
							color='primary'
							checked={app.userChangePass || false}
							onChange={(e) => setApp({ ...app, [e.target.name]: e.target.checked })}
							name='userChangePass'
						/>
					}
					label='Permitir a los usuarios cambiar su contraseña'
					labelPlacement='end'
				/>
				<TextField
					style={{ marginTop: 10 }}
					name='maxRoomsUser'
					value={app.maxRoomsUser}
					label='Cantidad máxima de salas por usuario'
					onChange={(e) => setApp({ ...app, maxRoomsUser: e.target.value })}
					variant='filled'
					select
				>
					{[
						{ label: '5', value: 5 },
						{ label: '10', value: 10 },
						{ label: '15', value: 15 },
						{ label: '15+', value: -1 }
					].map((v) => (
						<MenuItem value={v.value} key={v.label}>
							{v.label}
						</MenuItem>
					))}
				</TextField>
			</Box>
			<Button className={classes.saveButton} variant='contained' color='primary' onClick={saveSettings}>
				Actualizar configuración
			</Button>
		</Box>
	)
}
