import React, { useEffect, useState } from 'react'
import {
	makeStyles,
	Box,
	Typography,
	Button,
	TextField,
	InputAdornment,
	Paper,
	IconButton,
	useTheme,
	Menu,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Divider,
} from '@material-ui/core'
import {
	HomeRounded,
	LinkRounded,
	FileCopyRounded,
	MoreVertRounded,
	AddRounded,
	FiberManualRecordRounded,
	StarRounded,
	ErrorRounded,
	ArrowBackRounded,
	VideocamRounded,
} from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'

import { showSnack } from '../../../actions/Feedback/showSnack'
import { startLoading } from '../../../actions/Feedback/startLoading'
import { endLoading } from '../../../actions/Feedback/endLoading'
import { apiGet, apiPost, apiDelete, apiGetWithParams } from '../../../api'
import { urlRooms, invitationLink } from '../../../api/urls'
import AddUsersDialog from '../../resources/AddUsersDialog'
import ConfigRoomDialog from '../../resources/ConfigRoomDialog'
import LogoCard from '../../resources/LogoCard'
import Recordings from '../Recordings'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '80%',
		padding: '10%',
		paddingTop: 30,
	},
	title: {
		display: 'flex',
		flexDirection: 'row',
		margin: 15,
		marginLeft: 0,
	},
	iniciarBox: {
		margin: 15,
		marginLeft: 0,
		height: 80,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
	},
	invitarBox: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	invitarLink: {
		marginRight: 10,
		marginTop: 0,
		marginBottom: 0,
	},
	iniciarButton: {
		height: '80%',
		width: '20%',
		fontSize: 25,
	},
	roomIcon: {
		padding: 7,
		borderRadius: 3,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
	},
	bottomContainer: {
		display: 'flex',
		flexDirection: 'row',
		width: '100%',
        flexWrap: 'wrap',
        marginBottom: 15
	},
	roomInfo: {
		width: '85%',
		padding: 15,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexGrow: 1,
		'&:hover': {
			backgroundColor: theme.palette.background.level2,
			cursor: 'pointer',
		},
	},
	newRoomInfo: {
		flexGrow: 1,
	},
	roomOptions: {
		width: '15%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	room: {
		width: '30%',
		margin: 15,
		marginLeft: 0,
		marginRight: 30,
		minWidth: 370,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.background.level1,
	},
	roomText: {
		flexGrow: 1,
		padding: '0px 15px',
	},
	infoRoomText: { marginRight: 15 },
	newRoom: {
		borderStyle: 'dashed',
		backgroundColor: 'transparent',
	},
	rowCenterAlign: {
		display: 'flex',
		alignItems: 'center',
	},
	labeledSwitch: {
		width: '100%',
		margin: '5px 0px',
		justifyContent: 'space-between',
	},
	tableContainer: {
		width: '100%',
	},
	tableSeachContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: 'calc(100% - 30px)',
		padding: 15,
	},
	tableTitleContainer: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

export default function Home() {
	const classes = useStyles()
    const theme = useTheme()
    const permissions = useSelector(state=>state.login.permissions)
	const dispatch = useDispatch()
	const [openMenu, setOpenMenu] = useState({ anchorEl: null, id: 0 })
	const [configure, setConfigure] = useState({ open: false, edit: false })
	const [room, setRoom] = useState({})
	const [rooms, setRooms] = useState([])
	const [delRooms, setDelRooms] = useState({ id: null, open: false })
	const [doFetch, setDoFetch] = useState(0)
	const [addUsers, setAddUsers] = useState({ open: false, room: {} })
	const [seeRecordings, setSeeRecordings] = useState({ open: false, search: '' })

	useEffect(() => {
        setTimeout(() => setDoFetch(doFetch + 1), 60000)
        if (doFetch === 0) dispatch(startLoading())
		apiGet(urlRooms).then((r) => {
			if (r.status === 'OK') {
				const rms = r.data.sort((a, b) => (a.running ? -1 : 1))
				setRooms(rms)
				const updated = rms.filter((r) => r.uid === room.uid)
				setRoom(updated.length === 1 ? updated[0] : rms.length > 0 ? rms[0] : [])
            }
            if (doFetch === 0) dispatch(endLoading())
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setRoom, setRooms, doFetch, setDoFetch])

	const copyLink = () => {
		var copyText = document.getElementById('copytext')
		copyText.select()
		copyText.setSelectionRange(0, 99999) /*For mobile devices*/
		if (document.execCommand('copy')) dispatch(showSnack('Link copiado', 'info'))
	}

	const cleanReacordings = () => {
		setSeeRecordings({ open: false, data: [], search: '' })
	}

	const iniOrJoin = () => {
		let params = {}
		if (room.options.canStart && !room.running) params = {start: room.uid}
        else params = {join: room.uid}
        dispatch(startLoading())
		apiGetWithParams(urlRooms, {...params, logoutURL: window.location.toString()}).then((r) => {
			if (r.status === 'OK') 
                window.location.replace(r.data.url)
            else if (r.status === 'NOT_RUNNING'){
				dispatch(showSnack('La sala no está iniciada', 'info'))
				setDoFetch(doFetch-1)
            }else dispatch(showSnack('Algo salió mal, vuelva a intentar mas tarde', 'error'))
            dispatch(endLoading())
		})
	}

	const endSession = (r) => {
        dispatch(startLoading())
		apiPost(urlRooms + '?end=' + r.uid, {}).then((r) => {
			if (r.status === 'OK') {
				dispatch(showSnack('Sesion terminada', 'success'))
				setDoFetch(0)
            } else dispatch(showSnack('Algo salió mal', 'error'))
            dispatch(endLoading())
		})
	}

	const onDelRoom = () => {
        dispatch(startLoading())
		apiDelete(urlRooms, { ids: [delRooms.id] }).then((r) => {
			if (r.status === 'OK') {
				setDoFetch(0)
				setDelRooms({ open: false, id: null })
				dispatch(showSnack('Sala eliminada con éxito', 'success'))
            } else dispatch(showSnack('Error al eliminar sala', 'error'))
            dispatch(endLoading())
		})
	}

	return (
		<Box className={classes.root}>
			<Dialog open={delRooms.open} fullWidth maxWidth='xs'>
				<DialogTitle>Eliminar sala</DialogTitle>
				<Divider />
				<DialogContent>
					<Typography>¿Está seguro que desea eliminar la sala?</Typography>
				</DialogContent>
				<Divider />
				<DialogActions>
					<Button onClick={() => setDelRooms({ open: false, id: 0 })}>Cancelar</Button>
					<Button variant='contained' color='secondary' onClick={onDelRoom}>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
			{permissions.indexOf('CONFIG SALA') !== -1 ? <ConfigRoomDialog
				open={configure.open}
				onClose={() => setConfigure({ open: false, edit: false, room: { settings: {}} })}
				edit={configure.edit}
				room={configure.room}
				update={() => setDoFetch(doFetch + 1)}
			/>:null}
			<Box className={classes.title}>
				<Typography variant='h2' color='textPrimary'>
					{room.name || 'No hay salas disponibles'}
				</Typography>
				{room.owner ? <StarRounded fontSize='large' color='primary' /> : null}
			</Box>
			{room.uid ? (
				<Box display='flex' alignItems='center' marginBottom={1}>
					<Typography className={classes.infoRoomText} variant='subtitle1' color='textPrimary'>
						{room.sessions} Sesion{room.sessions === 1 ? '' : 'es'} |
					</Typography>
					{!seeRecordings.open ? (
						<Button
							variant='text'
							color='secondary'
							onClick={() => setSeeRecordings({ open: true, search: '' })}
							startIcon={<VideocamRounded />}
						>
							Ver grabaciones
						</Button>
					) : null}
				</Box>
			) : null}
			<Box className={classes.rowCenterAlign}>
				<Button
					className={classes.iniciarButton}
					variant='contained'
					color='primary'
					onClick={() => iniOrJoin()}
					disabled={!room.options?.canStart && !room.running}
				>
					{room.options?.canStart && !room.running ? 'Iniciar' : 'Unirse'}
				</Button>
				{!room.options?.canStart && !room.running ? (
					<Box className={classes.rowCenterAlign} style={{ marginLeft: 15, alignItems: 'flex-end' }}>
						<ErrorRounded style={{ marginBottom: 3, marginRight: 5 }} color='error' />
						<Typography variant='subtitle1' color='textPrimary'>
							Sala no iniciada
						</Typography>
					</Box>
				) : null}
			</Box>
			{room.uid && (room.owner || room.extras.canShare) ? (
				<Box className={classes.iniciarBox}>
					<Box className={classes.invitarWrapper}>
						<Typography variant='subtitle2' color='textPrimary' paragraph>
							Invitar participantes
						</Typography>
						<Box className={classes.invitarBox}>
							<TextField
								id='copytext'
								margin='dense'
								className={classes.invitarLink}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<LinkRounded />
										</InputAdornment>
									),
								}}
								value={invitationLink + room.uid}
								variant='outlined'
								inputProps={{ readOnly: true }}
							>
								<LinkRounded color='primary' />
							</TextField>
							<Button
								className={classes.copyButton}
								startIcon={<FileCopyRounded />}
								variant='contained'
								color='primary'
								onClick={copyLink}
							>
								Copiar
							</Button>
						</Box>
					</Box>
				</Box>
			) : null}
			<AddUsersDialog
				open={addUsers.open}
				room={addUsers.room}
				onClose={() => setAddUsers({ open: false, room: {} })}
			/>
			<Box className={classes.bottomContainer}>
				{seeRecordings.open ? (
					<Paper className={classes.tableContainer}>
						<Box className={classes.tableSeachContainer}>
							<Box className={classes.tableTitleContainer}>
								<IconButton color='primary' onClick={cleanReacordings}>
									<ArrowBackRounded />
								</IconButton>
								<Typography>Grabaciones</Typography>
							</Box>
							<TextField
								variant='outlined'
								value={seeRecordings.search}
								name='search'
								label='Buscar'
								onChange={(e) => seeRecordings({ ...seeRecordings, search: e.target.value })}
							/>
						</Box>
						<Recordings room={room} search={seeRecordings.search} />
					</Paper>
				) : (
					<React.Fragment>
						{rooms.map((r) => (
							<Paper key={'pp-' + r.uid} className={classes.room} variant='outlined' elevation={0}>
								<Box
									key={'bx1-' + r.uid}
									className={classes.roomInfo}
									onClick={() => {
										setRoom(r)
										window.scrollTo(0, 0)
									}}
								>
									<Box key={'bx2-' + r.uid} className={classes.roomIcon}>
										{r.owner ? (
											<StarRounded key={'hr-' + r.uid} color='inherit' />
										) : (
											<HomeRounded key={'hr-' + r.uid} color='inherit' />
										)}
									</Box>
									<Box key={'bx3-' + r.uid} className={classes.roomText}>
										<Box className={classes.rowCenterAlign}>
											<Typography key={'tp1-' + r.uid} variant='subtitle1'>
												{r.name}
											</Typography>
											<FiberManualRecordRounded
												fontSize='small'
												style={{
													marginBottom: 3,
													color: r.running
														? theme.palette.success.main
														: theme.palette.error.main,
												}}
											/>
										</Box>
										<Typography key={'tp2-' + r.uid} variant='caption'>
											{r.sessions
												? `${r.sessions} sesion${r.sessions === 1 ? '' : 'es'}`
												: 'Esta sala todavia no tiene sesiones'}
										</Typography>
									</Box>
								</Box>
								{r.owner ? (
									<Box key={'bx4-' + r.uid} className={classes.roomOptions}>
										<IconButton
											key={'ib-' + r.uid}
											onClick={(e) => setOpenMenu({ anchorEl: e.currentTarget, id: r.uid })}
										>
											<MoreVertRounded key={'mv-' + r.uid} color='inherit' />
										</IconButton>
										<Menu
											anchorEl={openMenu.anchorEl}
											open={openMenu.id === r.uid}
											onClose={() => setOpenMenu({ anchorEl: null, id: 0 })}
										>
											{permissions.indexOf('CONFIG SALA') !== -1 ?<MenuItem
												onClick={() => {
													setOpenMenu({ anchorEl: null, id: 0 })
													setConfigure({ open: true, room: r, edit: true })
												}}
											>
												Configuración
											</MenuItem> : null}
											<MenuItem
												onClick={() => {
													setOpenMenu({ anchorEl: null, id: 0 })
													setAddUsers({ open: true, room: r })
												}}
											>
												Administrar acceso
											</MenuItem>
											{r.running ? (
												<MenuItem
													onClick={() => {
														setOpenMenu({ anchorEl: null, id: 0 })
														endSession(r)
													}}
												>
													Finalizar sesión
												</MenuItem>
											) : null}
											{r.owner ? (
												<MenuItem
													onClick={() => {
														setDelRooms({ open: true, id: r.uid })
														setOpenMenu({ anchorEl: null, id: 0 })
													}}
												>
													Eliminar
												</MenuItem>
											) : null}
										</Menu>
									</Box>
								) : null}
							</Paper>
						))}
						{permissions.indexOf('CREAR SALA') !== -1 ? <Paper className={clsx(classes.room, classes.newRoom)} variant='outlined' elevation={0}>
							<Box
								className={clsx(classes.roomInfo, classes.newRoomInfo)}
								onClick={() =>
									setConfigure({ ...configure, open: true, edit: false, room: { settings: {} } })
								}
							>
								<Box className={classes.roomIcon}>
									<AddRounded color='inherit' />
								</Box>
								<Box className={classes.roomText}>
									<Typography align='center'>Crear una sala</Typography>
								</Box>
							</Box>
						</Paper> : null}
					</React.Fragment>
				)}
			</Box>
            <LogoCard />
		</Box>
	)
}
